$(document).ready(function() {
    $(location).attr('href');
    var pathname = window.location.pathname;

    $.urlParam = function(name){
        var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results==null){
           return null;
        }
        else{
           return results[1] || 0;
        }
    }

    // ?utm_source=autoxblesk&utm_medium=clanek_proklik&utm_campaign=proklik
    //alert ($.urlParam('utm_source'));     // autoxblesk
    //alert ($.urlParam('utm_medium'));     // clanek_proklik
    //alert ($.urlParam('utm_campaign'));   // proklik
    
    var par1 = $.urlParam('utm_source');
    var par2 = $.urlParam('utm_medium');
    var par3 = $.urlParam('utm_campaign');
    
    //alert (par1 + ' -- ' + par2 + ' -- ' + par3); 

    if(par1 == 'autoxblesk'){
      if(par2 == 'clanek_proklik'){
        if(par3 == 'proklik'){
          $('.autoBookmark').css('display','block');
          $('.autoBookmarkLeft').css('display','block');
        }
      }
    }

});

